.ourValue {
  background: linear-gradient(135deg, #7355f7, #8a79f7);
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.sectionTitle {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourValueDetails {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1.5rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  cursor: pointer;
}

.ourValueDetails:hover {
  box-shadow: 0 8px 60px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
}

.image:hover {
  transform: scale(1.05);
}

@media (max-width: 981px) {
  .ourValueDetails {
    margin-top: 1rem;
  }
}

/* Adding animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
