.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: rgba(
    230,
    230,
    250,
    0.7
  ); /* Semi-transparent light purple background color */
  backdrop-filter: blur(10px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* Apply blur effect for Safari */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: backdrop-filter 0.5s ease, transform 0.5s ease; /* Smooth transition for blur and transform */
  text-align: left;
}

.terms-container:hover {
  backdrop-filter: blur(12px); /* Increase blur effect on hover */
  transform: scale(1.02); /* Slightly scale up on hover */
}

.terms-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #4b0082; /* Darker purple for the heading */
  transition: color 0.5s ease; /* Smooth transition for color */
}

.terms-container p,
.terms-container ul {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: justify;
  color: #333; /* Dark gray text color for readability */
  transition: color 0.5s ease; /* Smooth transition for color */
}

.terms-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.terms-container li {
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 981px) {
  .terms-container {
    padding: 10px;
    border-radius: 10px; /* Slightly reduce border radius for smaller screens */
    backdrop-filter: blur(8px); /* Adjust blur effect for smaller screens */
    -webkit-backdrop-filter: blur(
      8px
    ); /* Adjust blur effect for Safari on smaller screens */
  }

  .terms-container h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .terms-container p,
  .terms-container ul {
    max-width: 90%; /* Increase max-width for better readability on smaller screens */
  }
}
