/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    color: #6128d9;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .blog {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 40px;
    padding: 20px;
    background-color: #f6f5ff;
    border-radius: 10px;
  }
  
  .blog-header h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .blog-header p {
    font-size: 1.1rem;
    color: #555;
  }
  
  .blog-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .blog-post {
    flex: 3;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .blog-post h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-post p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .blog-post section h3 {
    font-size: 1.5rem;
    color: #6128d9;
    margin-top: 20px;
  }
  
  .blog-post .cta {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
  }
  
  .blog-post .cta a {
    color: #ff4f66;
    font-weight: bold;
  }
  
  .related-posts {
    margin-top: 40px;
  }
  
  .related-posts h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .related-posts ul {
    list-style: none;
    padding: 0;
  }
  
  .related-posts ul li {
    margin-bottom: 10px;
  }
  
  .blog-sidebar {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .blog-sidebar section {
    margin-bottom: 20px;
  }
  
  .blog-sidebar h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-sidebar p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .newsletter form {
    display: flex;
    flex-direction: column;
  }
  
  .newsletter input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .newsletter button {
    padding: 10px;
    background-color: #6128d9;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .newsletter button:hover {
    background-color: #491ca5;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .blog-content {
      flex-direction: column;
    }
  
    .blog-header h1 {
      font-size: 2rem;
    }
  
    .blog-header p {
      font-size: 1rem;
    }
  
    .blog-post {
      margin-bottom: 20px;
    }
  
    .blog-sidebar {
      margin-bottom: 20px;
    }
  }
  