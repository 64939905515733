/* Reviews.css */

/* Define custom styles for the animated slider */
.carousel {
  width: 65%; /* Adjust the width as needed */
  margin: auto; /* Center the carousel horizontally */
  border-radius: 20px; /* Make the carousel round */
  overflow: hidden; /* Ensure content is hidden outside the border radius */
  box-shadow: 0px 0px 20px rgba(112, 83, 241, 0.5); /* Add a purple shadow */
  position: relative; /* Position the carousel container */
  background: rgba(255, 255, 255, 0.1); /* Add glassmorphism background */
  backdrop-filter: blur(10px); /* Add blur effect */
  transition: backdrop-filter 0.4s, background 0.4s; /* Smooth transition for glassmorphism effect */
}

.carousel-item {
  position: relative; /* Position the carousel items */
  overflow: hidden; /* Ensure content is hidden outside the border radius */
  transform-style: preserve-3d; /* Preserve 3D space */
}

.carousel-item img {
  width: 100%; /* Make the image fill the container */
  height: auto; /* Auto height to maintain aspect ratio */
  object-fit: cover; /* Cover the container without stretching */
  transition: transform 0.5s, opacity 0.5s; /* Add smooth transition for transform and opacity properties */
  border-radius: 20px; /* Add border-radius for rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.carousel-item .carousel-caption {
  position: absolute; /* Position the caption */
  bottom: 20px; /* Set distance from bottom */
  left: 50%; /* Align to the center */
  transform: translateX(-50%); /* Center horizontally */
  background: rgba(
    255,
    255,
    255,
    0.6
  ); /* Semi-transparent white background for glassmorphism */
  backdrop-filter: blur(5px); /* Blur effect for caption */
  padding: 10px 20px; /* Add padding */
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  text-align: center; /* Center align text */
  opacity: 0; /* Initially hide caption */
  transition: opacity 0.5s, backdrop-filter 0.5s, background 0.5s; /* Add transition for opacity and glassmorphism effects */
}

.carousel-item:hover img {
  transform: scale(1.05); /* Enlarge image on hover */
}

.carousel-item:hover .carousel-caption {
  opacity: 1; /* Show caption on hover */
}

.carousel-item img,
.carousel-caption {
  backface-visibility: hidden; /* Hide backface of elements */
}

.carousel-item.active {
  transform: rotateY(0deg); /* Rotate active item to front */
}

.carousel-item.next {
  transform: rotateY(180deg); /* Rotate next item to back */
}

.carousel-item.prev {
  transform: rotateY(-180deg); /* Rotate previous item to back */
}

/* Style the title */
.carousel-title {
  font-size: 2rem; /* Set the font size */
  color: #7053f1; /* Set the text color */
  margin-bottom: 10px; /* Add some space below the title */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .carousel {
    width: 90%; /* Adjust the width as needed */
    margin: auto; /* Center the carousel horizontally */
  }
  .carousel-item img {
    max-height: 300px; /* Adjust the max-height for smaller screens */
  }
}
