/* Contact Section */
#contact {
  padding: 7rem 0 6rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  backdrop-filter: blur(10px);
}

/* Section Title */
.sectionTitle {
  margin: 0.5rem 0 3rem;
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-bottom: 0.6rem;
}

/* Title Underline */
.sectionTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100px;
  border-radius: 70px;
  height: 4px;
  background: #7355f7;
}

/* Contact Form */
.contactForm {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: fadeIn 1s ease-in-out;
}

/* Form Input and Textarea */
.contactForm input,
.contactForm textarea {
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(112, 84, 242, 0.6); /* Always show border */
  background: rgba(255, 255, 255, 0.1);
  color: #000;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: all 0.3s ease-in-out;
}

/* Input and Textarea Focus */
.contactForm input:focus,
.contactForm textarea:focus {
  outline: none !important;
  border: 1px solid #7054f2;
  background-color: rgba(243, 240, 255, 0.6);
  box-shadow: 0 0 5px rgba(112, 84, 242, 0.6);
}

/* Textarea Height */
.contactForm textarea {
  height: 120px;
}

/* Fade In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
