/* Pricing Section */
.pricing {
  padding: 7rem 0 6rem;
  font-family: "Poppins", sans-serif;
  background: rgba(
    255,
    255,
    255,
    0.6
  ); /* Semi-transparent background for glass effect */
  backdrop-filter: blur(10px); /* Initial blur effect */
  transition: backdrop-filter 0.3s ease, background 0.3s ease; /* Smooth transitions */
}

/* Pricing Navigation */
.pricingNav {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.pricingNav img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pricingNav .nav-link {
  position: relative;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  transition: 0.3s;
}

.priceLink1 > .nav-link {
  border: 7px solid #ffccd3;
}

.priceLink3 > .nav-link {
  border: 7px solid #fdd2ff;
}
.priceLink4 > .nav-link {
  border: 7px solid #cbedff;
}
.priceLink5 > .nav-link {
  border: 7px solid #dfdaff;
}
.priceLink6 > .nav-link {
  border: 7px solid #ffeb94;
}

.priceLink1 > a:hover,
.priceLink1 > .nav-link.active {
  background: #ff4f66;
}

.priceLink3 > a:hover,
.priceLink3 > .nav-link.active {
  background: #f78cff;
}
.priceLink4 > a:hover,
.priceLink4 > .nav-link.active {
  background: #dad6fe;
}
.priceLink5 > a:hover,
.priceLink5 > .nav-link.active {
  background: #755bff;
}
.priceLink6 > a:hover,
.priceLink6 > .nav-link.active {
  background: #ffd30f;
}

/* Pricing Card Component Design */
.pricingCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 0 50px rgb(0 0 0 / 9%);
  border: 2px solid #815cf6;
  border-radius: 0.26rem !important;
  transition: all 1s;
  background: rgba(255, 255, 255, 0.7); /* Glass effect background */
  backdrop-filter: blur(5px); /* Initial blur */
  padding: 1rem;
  margin-top: 2rem;
  color: white;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  height: 450px; /* Set fixed height for uniform size */
  margin: 1rem; /* Add margin for spacing */
}

.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.1;
}

.pricingBox {
  text-align: center;
  margin-bottom: 1rem;
}

.pricingBox h4 {
  font-weight: 700;
  padding: 0.5rem 0;
  margin-bottom: 0;
  color: white;
}

.pricingBox h5 {
  font-weight: 700;
  font-size: 1rem;
  color: white;
}

.planDescription {
  font-size: 15px;
  color: #dad6fe;
  line-height: 1.8;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #f6f5ff;
  margin: 1rem 0;
}

.pricingCard ul {
  padding: 0;
  flex-grow: 1; /* Allow ul to grow and take available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricingCard li {
  list-style: none;
  margin: 0.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10;
}

.ph1,
.ph2,
.ph3,
.ph4,
.ph5,
.ph6 {
  font-size: 1.6rem;
  font-weight: 700;
  color: white;
}

.pricingCard1 {
  background: #ffd2c4;
}
.ph1,
.pricingCard1 > li > .checkIcon {
  color: #ff4f66;
}

.pricingCard2 {
  background: #7355f7;
}
.ph2,
.pricingCard2 > li > .checkIcon {
  color: #dad6fe;
}

.pricingCard3 {
  background: #d002e78b;
}
.ph3,
.pricingCard3 > li > .checkIcon {
  color: #f565ff;
}

.pricingCard4 {
  background: #d2efff;
}
.ph4,
.pricingCard4 > li > .checkIcon {
  color: #5bc5ff;
}

.pricingCard5 {
  background: #ded9ff;
}
.ph5,
.pricingCard5 > li > .checkIcon {
  color: #755bff;
}

.pricingCard6 {
  background: #fff1d5;
}
.ph6,
.pricingCard6 > li > .checkIcon {
  color: #f5c800;
}

/* Center Pay Now Button */
.paynow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn.paynow {
  background: #ece9fe; /* Background color with transparency */
  padding: 15px 30px; /* Padding */
  border-radius: 50px; /* Border radius */
  font-size: 18px; /* Font size */
  font-weight: 600; /* Font weight */
  line-height: 1.2; /* Line height */
  text-transform: uppercase; /* Uppercase text */
  border: none; /* No border */
  color: #6128d9; /* Text color */
  transition: background-color 0.4s ease, transform 0.3s ease,
    backdrop-filter 0.4s ease; /* Transition for background, transform, and backdrop-filter */
  box-shadow: 0 4px 15px rgba(113, 84, 243, 0.5); /* Box shadow for depth */
  cursor: pointer; /* Cursor style */
}

.btn.paynow:hover {
  background-color: rgba(75, 36, 245, 0.8); /* Background color on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
  backdrop-filter: blur(15px); /* Increased blur effect on hover */
  color: #ece9fe;
}

@media (max-width: 1400px) {
  .pricingCard {
    height: auto; /* Allow height to adjust on smaller screens */
  }

  .btn.paynow {
    margin-top: auto; /* Ensure the button is at the bottom */
  }
}

@media (max-width: 768px) {
  .pricingNav {
    flex-direction: column;
    align-items: center;
  }

  .pricing {
    padding: 4rem 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricingCard {
    padding: 1rem;
    margin-top: 1rem;
    max-width: 90%;
    height: auto; /* Allow height to adjust on smaller screens */
    margin-left: auto;
    margin-right: auto;
  }

  .planDescription {
    font-size: 14px;
    line-height: 1.6;
  }

  .ph1,
  .ph2,
  .ph3,
  .ph4,
  .ph5,
  .ph6 {
    font-size: 1.4rem;
  }
}

/* Glassmorphism Effect */
.pricingCard.glass-effect {
  backdrop-filter: blur(20px); /* Increased blur effect */
  background: #7355f7; /* More transparent background */
}
