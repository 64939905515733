/* Footer Section */
.footer {
  background: #7355f7; /* Background color */
  background-image: url("../../../Assets/footerbg.png"); /* Background image */
  width: 100%;
  font-family: "Poppins", sans-serif;
  margin: 0 !important; /* Reset margin */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

/* Footer Box */
.footerBox .footerLogo {
  background-color: rgba(255, 255, 255, 0.1); /* Background color */
  border-radius: 3px; /* Border radius */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Box shadow */
  height: 70px; /* Height */
  padding: 0.5rem; /* Padding */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.footerBox .footerLogo:hover {
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

/* Link Icon */
.linkIcon {
  color: #7355f7; /* Icon color - Changed for better visibility */
  font-size: 2rem; /* Font size */
  cursor: pointer;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background for better visibility */
  padding: 5px; /* Padding */
  margin: 0 0.5rem; /* Margin */
  border-radius: 0.3rem; /* Border radius */
  transition: transform 0.3s ease, background 0.3s ease, color 0.3s ease;
}

.linkIcon:hover {
  transform: rotate(15deg) scale(1.2);
  background: #fff; /* Fully opaque white background on hover */
  color: #4b24f5; /* Darker color on hover for contrast */
}

/* Footer Link and About Us */
.footerLink,
.fAboutUs {
  padding: 1.5rem 2rem; /* Padding */
}

/* Footer Link and About Us Title */
.footerLink h5,
.fAboutUs h5 {
  color: rgba(240, 255, 255, 0.925); /* Title color */
  padding-top: 0.5rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

/* Footer Link Title */
.footerLink h5 {
  padding-bottom: 0.5rem;
}

/* Footer Link Title Underline */
.footerLink h5::after {
  content: ""; /* Title underline */
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 60px;
  border-radius: 70px;
  height: 4px;
  background: rgba(240, 255, 255, 0.925); /* Title underline color */
}

/* Footer About Us Description */
.footerLink > .aboutUsDes {
  color: rgba(255, 255, 255, 0.6); /* Description color */
  font-weight: 400;
}

/* Footer Link List */
.footerLink li {
  list-style: none;
  color: rgba(255, 255, 255, 0.6); /* Link color */
  font-size: 1rem;
  transition: 0.4s;
  margin: 1.1rem 0; /* Margin */
  font-weight: 400;
}

/* Footer Link Hover */
.footerLink li:hover {
  margin-left: 0.7rem;
  color: #fff; /* Hover color */
}

/* Footer Link */
.footerLink a {
  color: rgba(255, 255, 255, 0.6); /* Link color */
  text-decoration: none;
  transition: color 0.3s ease;
}

/* Footer Link Hover */
.footerLink a:hover {
  color: #fff; /* Hover color for better visibility */
  text-decoration: none;
}

/* Footer Arrow Icon */
.footArrowIcon {
  font-size: 0.9rem; /* Icon size */
}

/* Footer About Us Paragraph */
.fAboutUs p {
  color: rgba(255, 255, 255, 0.699); /* Paragraph color */
  font-weight: 400;
  margin: 1rem 0;
}

/* Footer About Us List */
.fAboutUs ul {
  margin: 0;
  padding: 0;
}

/* Footer About Us List Item */
.fAboutUs li {
  list-style: none;
  float: left;
}

/* Footer About Us Link */
.fAboutUs li a {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background for better visibility */
  margin-right: 5px; /* Margin */
  color: #7355f7; /* Link color */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: background 0.4s, color 0.4s;
}

/* Footer About Us Link Hover */
.fAboutUs li a:hover {
  background: #4b24f5; /* Hover background color */
  color: #fff; /* Hover color */
}

/* Copyright */
.copyRight {
  background-color: rgba(38, 8, 171, 0.9); /* Background color */
  color: rgba(255, 255, 255, 0.692); /* Text color */
  font-weight: 400;
  font-size: 0.911rem;
  text-align: center;
  margin-bottom: 0;
  padding: 1rem 0; /* Padding */
}

/* Copyright Highlight */
.copyRight > .fHighlight {
  color: #fff; /* Highlight color */
  font-weight: 400;
  font-size: 1rem;
}

/* Footer Info */
.footerInfo {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6); /* Border color */
  padding: 3rem 0 2rem; /* Padding */
}

/* Footer Contact Information */
.fContactInfo p {
  color: rgba(255, 255, 255, 0.6); /* Paragraph color */
  font-size: 1rem;
  margin-bottom: 0.2rem;
  margin-left: 0.7rem;
}

/* Footer Contact Icon */
.fContactIcon {
  font-size: 2.5rem; /* Icon size */
  color: rgba(255, 255, 255, 0.822); /* Icon color */
}

/* Footer Contact Information Title */
.fContactInfo1 p {
  color: #fff !important; /* Title color */
  font-weight: 500;
  font-size: 1.5rem !important; /* Title font size */
}

/* Footer Contact Icon */
.fContactInfo1 .fContactIcon {
  color: #fff; /* Icon color */
  font-weight: 500;
  font-size: 3rem; /* Icon size */
}

/* Animation Border */
.animate-border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: #2608ab; /* Background color */
}

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 20px solid #fff; /* Border color */
  -webkit-animation: animborder 3s linear infinite; /* Animation */
  animation: animborder 3s linear infinite;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(81px);
    transform: translateX(81px);
  }
}

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(81px);
    transform: translateX(81px);
  }
}
