/* Navigation Styles */
.navStyle {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  background: rgba(
    255,
    255,
    255,
    0.7
  ); /* Semi-transparent background for glass effect */
  backdrop-filter: blur(10px); /* Blur effect */
  position: fixed !important; /* Fixed position */
  top: 0; /* Stick to top */
  width: 100%; /* Full width */
  z-index: 99 !important; /* Z-index */
  transition: 0.5s !important; /* Transition */
  font-family: "Poppins", sans-serif; /* Font family */
  padding: 15px 0; /* Padding */
}

.sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
/* Default Navigation */
.navDefault {
  transition: 0.5s !important; /* Transition */
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  padding: 25px 0 40px 0 !important; /* Padding */
}

/* Main Navigation Link */
.mainNav .nav-link {
  color: #070120 !important; /* Text color */
  margin: 0.5rem 1.2rem !important; /* Margin */
  font-weight: 500 !important; /* Font weight */
  transition: 0.3s; /* Transition */
  font-size: 1.1rem; /* Font size */
  font-family: "Poppins", sans-serif; /* Font family */
}

/* Main Navigation Link Hover Effect */
.mainNav .nav-link:after {
  content: ""; /* Empty content */
  display: block; /* Block display */
  margin: auto; /* Center horizontally */
  height: 3px; /* Height */
  width: 0px; /* Initial width */
  background: transparent; /* Transparent background */
  transition: width 0.5s ease, background-color 0.5s ease; /* Transition */
}

/* Main Navigation Link Hover Effect After */
.mainNav .nav-link:hover:after {
  width: 100%; /* Full width on hover */
  background: #4c25f5; /* Background color on hover */
}

/* Navigation Brand */
.navBrn {
  font-weight: 700; /* Font weight */
  font-size: 1.5rem; /* Font size */
}

/* Brand Icon and Highlight */
.brnIcon,
.navHighlight {
  color: #7456f7; /* Text color */
}

/* Brand Icon */
.brnIcon {
  font-weight: 800; /* Font weight */
  font-size: 1.6rem; /* Font size */
}

/* Login Button */
.loginBtn {
  outline: none; /* No outline */
  border: none; /* No border */
  border-radius: 50px; /* Border radius */
  font-weight: 500; /* Font weight */
  color: #fff; /* Text color */
  background: #7355f7; /* Background color */
  padding: 0.5rem 1.5rem; /* Padding */
  margin: 0.5rem 1rem; /* Margin */
  transition: 0.4s; /* Transition */
}

/* Login Button Hover Effect */
.loginBtn:hover {
  background: #4b24f5; /* Background color on hover */
}

/* Active Navigation Link Class */
.navActiveClass {
  color: red; /* Text color */
  font-weight: 900; /* Font weight */
}

/* Responsive Navigation Styles */
@media (max-width: 981px) {
  .navDefault {
    background: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    padding: 0.8rem 0.5rem !important; /* Padding */
    width: 100%; /* Full width */
    z-index: 99 !important; /* Z-index */
    transition: 0.5s !important; /* Transition */
    text-align: center; /* Text alignment */
  }
  .navStyle {
    text-align: center; /* Text alignment */
  }
  .mainNav .nav-link {
    font-size: 0.9rem;
    margin: 0.3rem 0.6rem !important;
  }
}
