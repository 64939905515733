.about {
  overflow: hidden;
  padding: 3rem 0;
}

.miniTitle {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #333;
}

.headerTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.headerHighlight {
  color: #007bff; /* Example highlight color */
}

.headerContent {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}
