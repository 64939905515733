/* Start Now Button Styles */
.start-now-button {
  background: #7355f7; /* Background color */
  padding: 18px 35px; /* Padding */
  display: inline-block; /* Display inline-block */
  border-radius: 50px; /* Border radius */
  font-size: 15px; /* Font size */
  font-weight: 500; /* Font weight */
  line-height: 1.2; /* Line height */
  text-transform: uppercase; /* Uppercase text */
  border: none; /* No border */
  color: #fff; /* Text color */
  transition: background-color 0.4s ease, transform 0.3s ease; /* Transition for background and transform */
  box-shadow: 0 4px 15px rgba(113, 84, 243, 0.5); /* Box shadow for depth */
}

/* Hover Effects for Start Now Button */
.start-now-button:hover {
  background-color: rgba(75, 36, 245, 0.8); /* Background color on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
  color: #fff; /* Text color on hover */
  backdrop-filter: blur(15px); /* Increased blur effect on hover */
}
