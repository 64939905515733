/* Scroll Button Styles */
.scrollBtn {
  position: fixed; /* Fixed position */
  bottom: 1rem; /* Distance from bottom */
  right: 1rem; /* Distance from right */
  cursor: pointer; /* Cursor style */
  font-weight: 400; /* Font weight */
  color: #fff; /* Text color */
  z-index: 112; /* Z-index */
  display: inline-block; /* Display as inline-block */
  height: 2.4rem; /* Height */
  width: 2.4rem; /* Width */
  background: rgba(113, 84, 243, 0.6); /* Background color with transparency */
  border-radius: 50%; /* Border radius for rounded shape */
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Transition */
  border: none; /* No border */
  box-shadow: 0 2px 15px rgba(88, 69, 206, 0.7); /* Box shadow */
  backdrop-filter: blur(10px); /* Backdrop filter for Glassmorphism */
}

/* Scroll Button Hover Effect */
.scrollBtn:hover {
  background-color: rgba(
    75,
    36,
    245,
    0.6
  ); /* Background color on hover with transparency */
  transform: scale(1.1); /* Scale effect on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .scrollBtn {
    height: 2rem; /* Adjust height for smaller screens */
    width: 2rem; /* Adjust width for smaller screens */
    bottom: 0.5rem; /* Adjust distance from bottom */
    right: 0.5rem; /* Adjust distance from right */
  }
}
