/* Header Section */
.header {
  position: relative;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  height: 100vh; /* Full height of the viewport */
  background-image: url("../../../Assets/bg.png"); /* Background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
  background-position: right; /* Position of the background image */
  width: 100%; /* Full width */
  overflow: hidden; /* Hide overflowing content */
  backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
  transition: backdrop-filter 0.4s, background 0.4s; /* Transition for smooth scroll effect */
  padding-left: 5%; /* Left padding for desktop */
  padding-right: 5%; /* Right padding for desktop */
}

/* Media Query for Responsive Design */
@media (max-width: 981px) {
  .header {
    padding-left: 2%; /* Left padding for smaller devices */
    padding-right: 2%; /* Right padding for smaller devices */
  }
}

/* Title Area */
.titleArea {
  padding: 1rem; /* Padding */
}

/* Mini Title */
.miniTitle {
  font-size: 22px; /* Font size */
  font-weight: 600; /* Font weight */
  margin-bottom: 5px; /* Margin bottom */
  color: #777777; /* Text color */
  text-transform: uppercase; /* Uppercase text */
}

/* Header Title */
.headerTitle {
  margin-bottom: 10px; /* Margin bottom */
  font-size: 60px; /* Font size */
  font-weight: 800; /* Font weight */
  font-family: sans-serif !important; /* Font family */
}

/* Header Content */
.headerContent {
  font-size: 18px; /* Font size */
  line-height: 1.5; /* Line height */
  color: #5e5e5e; /* Text color */
  font-weight: 400; /* Font weight */
  font-family: "Poppins", sans-serif; /* Font family */
  margin: 0.5rem 0 1rem; /* Margin */
}

/* Header Highlight */
.headerHighlight {
  color: #7355f7; /* Highlight color */
}

/* Media Query for Responsive Design */
@media (max-width: 981px) {
  .header {
    overflow: visible; /* Visible overflow */
    height: 100%; /* Full height */
  }
  .headerTitle {
    margin-bottom: 10px; /* Margin bottom */
    font-size: 40px; /* Font size */
    font-weight: 700; /* Font weight */
    font-family: sans-serif !important; /* Font family */
  }
}

/* Glassmorphism Effect */
.header.glass-effect {
  backdrop-filter: blur(20px); /* Increased blur effect */
  background: rgba(255, 255, 255, 0.5); /* More transparent background */
}

/* Brand Button (Improved Start Now Button) */
.branBtn {
  justify-content: center;
  align-items: center;
  background: #7355f7; /* Background color */
  padding: 18px 35px; /* Padding */
  display: inline-block; /* Display inline-block */
  border-radius: 50px; /* Border radius */
  font-size: 15px; /* Font size */
  font-weight: 500; /* Font weight */
  line-height: 1.2; /* Line height */
  text-transform: uppercase; /* Uppercase text */
  border: none; /* No border */
  color: #fff; /* Text color */
  transition: background-color 0.4s ease, transform 0.3s ease; /* Transition for background and transform */
  box-shadow: 0 4px 15px rgba(113, 84, 243, 0.5); /* Box shadow for depth */
}

.hero-wrapper {
  margin-top: 3rem; /* Initial margin */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-wrapper {
    margin-top: 2rem; /* Decrease margin on smaller screens */
  }
}

@media (max-width: 576px) {
  .hero-wrapper {
    margin-top: 1.5rem; /* Further decrease margin on even smaller screens */
  }
}
/* Brand Button Hover */
.branBtn:hover {
  background-color: rgba(75, 36, 245, 0.8); /* Background color on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
  color: #fff; /* Text color on hover */
  backdrop-filter: blur(15px); /* Increased blur effect on hover */
}
